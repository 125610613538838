import Banner from "../../assets/image/Banner.webp";
import { FaInstagram, FaWhatsapp, FaFacebook } from "react-icons/fa";
import { HeroCss, HeroImage, Text, SocialIcons } from "./styles";

const Hero = () => {
  return (
    <HeroCss id="home">
      {/* Imagem do banner */}
      <HeroImage src={Banner} alt="Banner Engenhoso Cell" />

      {/* Texto principal */}
      <Text>
        <h1>Engenhoso Cell</h1>
        <p>Smart Tech Solutions</p>
      </Text>

      {/* Ícones de redes sociais */}
      <SocialIcons>
        <a
          href="https://www.instagram.com/engenhosocell/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Instagram"
        >
          <FaInstagram />
        </a>
        <a
          href="https://facebook.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Facebook"
        >
          <FaFacebook />
        </a>
        <a
          href="https://wa.me/16981451428"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="WhatsApp"
        >
          <FaWhatsapp />
        </a>
      </SocialIcons>
    </HeroCss>
  );
};

export default Hero;
