import styled from "styled-components";
import { Cores } from "../../../styles/GlobalStyles";

export const ClientesCss = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: #f4f6f9;
`;

export const Header = styled.header`
  margin-bottom: 32px;
  border-bottom: 1px solid #e0e0e0;

  h1 {
    padding-top: 24px;
    color: ${Cores.textDark};
    font-size: 2rem;
    font-weight: 600;

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  th,
  td {
    padding: 18px 30px;
    text-align: left;
    border-bottom: 1px solid #f0f0f0;
  }

  th {
    background-color: #fafafa;
    font-weight: 600;
    color: ${Cores.textDark};
    font-size: 0.95rem;
    letter-spacing: 0.5px;
  }

  td {
    color: #555;
    font-size: 1rem;
  }

  tbody tr {
    transition: background-color 0.2s ease;

    &:hover {
      background-color: #f8f8f8;
    }

    &:last-child td {
      border-bottom: none;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const LoadingContainer = styled.div`
  text-align: center;
  font-size: 1.2rem;
  color: ${Cores.textDark};
`;

export const EmptyState = styled.div`
  text-align: center;
  padding: 40px;
  color: #666;

  p {
    margin-bottom: 16px;
  }
`;

export const ClientContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ClientCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 20px;
  margin: 15px;
  width: 300px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
  }

  @media (max-width: 768px) {
    width: 100%; // Se ajusta à largura completa em dispositivos móveis
    margin: 10px 0;
  }
`;

export const ClientInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ClientNameAndDate = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 1rem;
  color: #333;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ClientName = styled.p`
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
`;

export const ClientDate = styled.p`
  margin: 0;
  font-size: 1rem;
  color: #777;
`;

export const ClientContact = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 1rem;
  color: #555;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ClientPhone = styled.p`
  margin: 0;
`;

export const ClientEmail = styled.p`
  margin: 0;
`;

export const ClientInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
  font-size: 0.95rem;
  color: #666;

  @media (max-width: 768px) {
    font-size: 0.85rem;
  }

  p {
    margin: 0;
  }
`;

export const ClientItem = styled.div`
  margin: 10px;
  width: 30%;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 15px;
    width: 100%;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;
