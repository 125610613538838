import { StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import GlobalCss from "./styles/GlobalStyles";
import LoadingFallback from "./components/LoadingFallback";
import MainPage from "./pages/MainPage";
import Admin from "./Admin";

// Inicialização do elemento root
const rootElement = document.getElementById("root");
if (!rootElement) {
  console.error("Elemento root não encontrado. Verifique o arquivo HTML.");
  throw new Error("Failed to find root element");
}

// Renderização do aplicativo
createRoot(rootElement).render(
  <StrictMode>
    <GlobalCss />
    <BrowserRouter>
      <Suspense fallback={<LoadingFallback />}>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/admin/*" element={<Admin />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  </StrictMode>
);
