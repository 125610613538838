import { useEffect, useState } from "react";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { db } from "../../../config/firebase";
import {
  Header,
  Table,
  LoadingContainer,
  EmptyState,
  ClientesCss,
  ClientContainer,
  ClientCard,
  ClientName,
  ClientInfo,
} from "./styles";

interface Client {
  id: string;
  name: string;
  phone: string;
  email: string;
  timestamp: Date;
}

const ClientList = () => {
  const [clients, setClients] = useState<Client[]>([]);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const q = query(
          collection(db, "Orcamentos"),
          orderBy("timestamp", "desc")
        );

        const querySnapshot = await getDocs(q);
        const clientsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
          phone: doc.data().phone,
          email: doc.data().email,
          timestamp: doc.data().timestamp?.toDate(),
        })) as Client[];

        setClients(clientsData);
      } catch (error) {
        console.error("Erro ao buscar clientes:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchClients();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Check the initial window size

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (loading) {
    return (
      <ClientesCss>
        <LoadingContainer>Carregando lista de clientes...</LoadingContainer>
      </ClientesCss>
    );
  }

  // Função para formatar a data
  const formatDate = (date: Date) => {
    return date?.toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  return (
    <ClientesCss>
      <div className="container">
        <Header>
          <h1>Lista de Clientes</h1>
        </Header>

        {clients.length === 0 ? (
          <EmptyState>
            <p>Nenhum cliente cadastrado ainda.</p>
          </EmptyState>
        ) : isMobile ? (
          // Mobile view - Cards
          <ClientContainer>
            {clients.map((client) => (
              <ClientCard key={client.id}>
                <ClientName>{client.name}</ClientName>
                <ClientInfo>Telefone: {client.phone}</ClientInfo>
                <ClientInfo>Email: {client.email}</ClientInfo>
                <ClientInfo>
                  Data de Cadastro: {formatDate(client.timestamp)}
                </ClientInfo>
              </ClientCard>
            ))}
          </ClientContainer>
        ) : (
          // Desktop view - Table
          <Table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Telefone</th>
                <th>Email</th>
                <th>Data de Cadastro</th>
              </tr>
            </thead>
            <tbody>
              {clients.map((client) => (
                <tr key={client.id}>
                  <td>{client.name}</td>
                  <td>{client.phone}</td>
                  <td>{client.email}</td>
                  <td>{formatDate(client.timestamp)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </ClientesCss>
  );
};

export default ClientList;
