import styled from "styled-components";
import { Cores } from "../../styles/GlobalStyles";

export const StoreCss = styled.section`
  padding: 80px 0;
  background-color: ${Cores.primary};
`;

export const Title = styled.div`
  text-align: center;
  margin-bottom: 60px;

  h2 {
    font-size: 2rem;
    color: ${Cores.textDark};
    margin-bottom: 16px;
  }

  p {
    font-size: 1rem;
    color: #666;
  }
`;

export const StoreGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
  margin: 0 auto;
`;

export const StoreItem = styled.div`
  background-color: ${Cores.secondary};
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  img,
  video {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }

  h3 {
    font-size: 1.5rem;
    color: ${Cores.textDark};
    margin: 16px;
  }

  p {
    color: #666;
    margin: 0 16px 16px;
    line-height: 1.6;
  }
`;
