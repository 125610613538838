import { ReviewCard, ReviewsCss, ReviewsGrid, Title } from "./styles";
import { FaStar } from "react-icons/fa";

const reviews = [
  {
    name: "João Silva",
    rating: 5,
    date: "15/03/2024",
    comment:
      "Excelente atendimento! Consertaram meu celular no mesmo dia e o preço foi justo. Recomendo muito!",
    service: "Troca de Tela",
    image: "/path/to/joao-profile.jpg",
  },
  {
    name: "Maria Santos",
    rating: 5,
    date: "10/03/2024",
    comment:
      "Profissionais muito competentes e atenciosos. Resolveram o problema do meu iPhone rapidamente.",
    service: "Reparo de Placa",
    image: "/path/to/maria-profile.jpg",
  },
  {
    name: "Ana Costa",
    rating: 5,
    date: "01/03/2024",
    comment:
      "Super recomendo! Conseguiram recuperar todos os dados do meu celular que tinha caído na água.",
    service: "Danos por Líquidos",
    image: "/path/to/ana-profile.jpg",
  },
];

const Reviews = () => (
  <ReviewsCss>
    <div className="container">
      <Title>
        <h2>Avaliações dos Clientes</h2>
        <p>Veja o que nossos clientes dizem sobre nossos serviços</p>
      </Title>

      <ReviewsGrid>
        {reviews.map((review, index) => (
          <ReviewCard key={index}>
            <div className="header">
              <img
                src={review.image}
                alt={`Foto de ${review.name}`}
                className="profile-image"
              />
              <div className="profile-info">
                <h3>{review.name}</h3>
                <div className="rating">
                  {[...Array(review.rating)].map((_, i) => (
                    <FaStar key={i} />
                  ))}
                </div>
              </div>
            </div>
            <span className="service">{review.service}</span>
            <p className="comment">{review.comment}</p>
            <span className="date">{review.date}</span>
          </ReviewCard>
        ))}
      </ReviewsGrid>
    </div>
  </ReviewsCss>
);

export default Reviews;
