import styled from "styled-components";
import { Cores } from "../../styles/GlobalStyles";

export const HeroCss = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
`;

export const HeroImage = styled.img`
  width: 100%;
  height: 100vh;
  object-fit: cover;
`;

export const Text = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: ${Cores.secondary};
  z-index: 2;
  width: 100%;
  padding: 0 20px;

  h1 {
    font-size: 5rem;
    font-style: italic;
    white-space: nowrap;

    @media (max-width: 768px) {
      font-size: 2.5rem;
    }
  }

  p {
    font-size: 2rem;

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }
`;

export const SocialIcons = styled.div`
  position: absolute;
  bottom: 33%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 1.5rem;
  z-index: 2;

  a {
    color: ${Cores.secondary};
    font-size: 2rem;
    transition: transform 0.3s ease;

    &:hover {
      transform: translateY(-5px);
    }
  }
`;
