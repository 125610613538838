import { useState, useEffect } from "react";
import {
  FaBars,
  FaTimes,
  FaPhone,
  FaEnvelope,
  FaMapMarkerAlt,
} from "react-icons/fa";
import Logo from "../../assets/image/Logo.webp";
import {
  NavBarCss,
  Menu,
  Link,
  LinkItem,
  MobileMenuButton,
  LogoImage,
  MobileContactInfo,
  ContactItem,
} from "./styles";

const NavBar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Detectar scroll para alterar estilo da barra de navegação
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Função para rolar suavemente até uma seção
  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      setIsMobileMenuOpen(false); // Fecha o menu mobile após a navegação
    }
  };

  return (
    <NavBarCss isScrolled={isScrolled}>
      <div className="container">
        {/* Logo da aplicação */}
        <LogoImage>
          <img src={Logo} alt="Engenhoso Cell Logo" />
        </LogoImage>

        {/* Botão para abrir/fechar menu mobile */}
        <MobileMenuButton
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          aria-label="Alternar Menu"
          isScrolled={isScrolled} // Propriedade obrigatória
        >
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </MobileMenuButton>

        {/* Menu de navegação */}
        <Menu isOpen={isMobileMenuOpen}>
          <Link>
            <LinkItem isScrolled={isScrolled}>
              <a onClick={() => scrollToSection("home")}>Início</a>
            </LinkItem>
            <LinkItem isScrolled={isScrolled}>
              <a onClick={() => scrollToSection("services")}>Serviços</a>
            </LinkItem>
            <LinkItem isScrolled={isScrolled}>
              <a onClick={() => scrollToSection("form")}>Solicitar Orçamento</a>
            </LinkItem>
            <LinkItem isScrolled={isScrolled}>
              <a onClick={() => scrollToSection("store")}>Nossa Loja</a>
            </LinkItem>
            <LinkItem isScrolled={isScrolled}>
              <a onClick={() => scrollToSection("contact")}>Contato</a>
            </LinkItem>
          </Link>

          {/* Informações de contato no menu mobile */}
          <MobileContactInfo>
            <ContactItem>
              <FaPhone /> <span>16 98145-1428</span>
            </ContactItem>
            <ContactItem>
              <FaEnvelope /> <span>engenhosocell@gmail.com</span>
            </ContactItem>
            <ContactItem>
              <FaMapMarkerAlt />
              <span>Rua: Ulisses de Paula Garcia, 19 - Cristais Paulista</span>
            </ContactItem>
          </MobileContactInfo>
        </Menu>
      </div>
    </NavBarCss>
  );
};

export default NavBar;
