import { StoreCss, StoreGrid, StoreItem, Title } from "./styles";

const storeItems = [
  {
    type: "image",
    src: "/path/to/store-image1.jpg",
    title: "Nossa Loja",
    description: "Conheça nosso espaço moderno e acolhedor",
  },
  {
    type: "video",
    src: "/path/to/store-video.mp4",
    title: "Nosso Trabalho",
    description: "Veja como realizamos os reparos com precisão",
  },
  {
    type: "image",
    src: "/path/to/store-image2.jpg",
    title: "Equipamentos",
    description: "Ferramentas profissionais para um serviço de qualidade",
  },
];

const Store = () => (
  <StoreCss id="store">
    <div className="container">
      <Title>
        <h2>Nossa Loja</h2>
        <p>Conheça nosso espaço e estrutura</p>
      </Title>

      <StoreGrid>
        {storeItems.map((item, index) => (
          <StoreItem key={index}>
            {item.type === "image" ? (
              <img src={item.src} alt={item.title} />
            ) : (
              <video controls>
                <source src={item.src} type="video/mp4" />
                Seu navegador não suporta vídeos.
              </video>
            )}
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </StoreItem>
        ))}
      </StoreGrid>
    </div>
  </StoreCss>
);

export default Store;
