import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { Routes, Route, Navigate } from "react-router-dom";
import { auth } from "../config/firebase";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import Sidebar from "./components/Sidebar";
import { AdminContainer, MainContent } from "./styles";
import Clients from "./components/Clients";

const Admin = () => {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  if (loading) {
    return null;
  }

  if (!user) {
    return <Login />;
  }

  return (
    <AdminContainer isSidebarOpen={isSidebarOpen}>
      <div className="container">
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <MainContent isSidebarOpen={isSidebarOpen}>
          <Routes>
            <Route path="/orcamentos" element={<Dashboard />} />
            <Route path="/clientes" element={<Clients />} />
            <Route
              path="*"
              element={<Navigate to="/admin/orcamentos" replace />}
            />
          </Routes>
        </MainContent>
      </div>
    </AdminContainer>
  );
};

export default Admin;
