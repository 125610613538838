import styled from "styled-components";
import { Cores } from "../../../styles/GlobalStyles";

export const DashboardCss = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  margin-bottom: 24px;
  border-bottom: 1px solid #eee;

  h1 {
    padding-top: 24px;
    color: ${Cores.textDark};
    font-size: 1.8rem;

    @media (max-width: 768px) {
      padding: 0;
      font-size: 1.3rem;
    }
  }
`;

export const RequestsGrid = styled.div`
  display: grid;
  gap: 24px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const RequestCard = styled.div`
  background-color: #fff;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  h3,
  h4 {
    color: ${Cores.textDark};
    &:first-of-type {
      margin-top: 0;
    }
  }
`;

export const InfoGrid = styled.div`
  display: grid;
  gap: 12px;
`;

export const InfoItem = styled.div`
  font-size: 0.9rem;

  strong {
    color: ${Cores.textDark};
    margin-right: 8px;
  }

  &.description {
    grid-column: 1 / -1;

    strong {
      display: block;
      margin-bottom: 4px;
    }
  }
`;

export const LoadingMessage = styled.div`
  text-align: center;
  padding: 40px;
  font-size: 1.2rem;
  color: ${Cores.textDark};
`;

export const Button = styled.button`
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 16px;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
`;

export const PaymentForm = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Input = styled.input`
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;

  &:focus {
    outline: none;
    border-color: ${Cores.textDark};
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;

  @media (max-width: 480px) {
    flex-direction: column;
    width: 100%;

    button {
      width: 100%;
    }
  }
`;

export const ConfirmButton = styled(Button)`
  background-color: #28a745;

  &:hover {
    background-color: #218838;
  }
`;

export const CancelButton = styled(Button)`
  background-color: #dc3545;

  &:hover {
    background-color: #c82333;
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 32px;
  border-bottom: 1px solid #e1e1e1;
  overflow-x: auto;
  padding-bottom: 2px;

  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
  }

  @media (max-width: 768px) {
    gap: 8px;
  }
`;

export const Tab = styled.button<{ active: boolean }>`
  padding: 12px 24px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 0.9rem;
  color: ${(props) => (props.active ? "#007bff" : "#666")};
  border-bottom: 2px solid
    ${(props) => (props.active ? "#007bff" : "transparent")};
  transition: color 0.3s ease, border-bottom 0.3s ease;

  &:hover {
    color: #007bff;
  }

  white-space: nowrap;
`;
