import styled from "styled-components";
import { Cores } from "../../../styles/GlobalStyles";

interface SidebarProps {
  isOpen: boolean;
}

export const SidebarContainer = styled.div<SidebarProps>`
  width: ${({ isOpen }) => (isOpen ? "280px" : "80px")};
  height: 100vh;
  background: linear-gradient(180deg, ${Cores.secondary} 0%, #f8f9fa 100%);
  position: fixed;
  left: 0;
  top: 0;
  padding: 16px;
  transition: all 0.3s ease-in-out;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  z-index: 1000;

  @media (max-width: 768px) {
    width: ${({ isOpen }) => (isOpen ? "60%" : "80px")};
  }
`;

export const LogoContainer = styled.div<SidebarProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ isOpen }) => (isOpen ? "flex-start" : "center")};
  padding: 0 12px;
  margin-bottom: 32px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition: all 0.3s ease;
  }

  h1 {
    margin-left: ${({ isOpen }) => (isOpen ? "12px" : "0")};
    font-size: 1.2rem;
    color: ${Cores.textDark};
    opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
    transition: all 0.3s ease;
    white-space: nowrap;
  }
`;

export const MenuList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
`;

interface MenuItemProps {
  active?: boolean;
}

export const MenuItem = styled.li<MenuItemProps>`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  background: ${({ active }) => (active ? "#e3f2fd" : "transparent")};
  color: ${({ active }) => (active ? "#1976d2" : Cores.textDark)};

  &:hover {
    background: ${({ active }) => (active ? "#e3f2fd" : "#f5f5f5")};
    color: ${({ active }) => (active ? "#1976d2" : "#1976d2")};
  }

  svg {
    font-size: 20px;
    min-width: 20px;
  }

  span {
    margin-left: 12px;
    font-weight: 500;
    white-space: nowrap;
  }
`;

export const LogoutButton = styled.button<SidebarProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ isOpen }) => (isOpen ? "center" : "center")};
  width: 100%;
  padding: 12px 16px;
  border: none;
  border-radius: 12px;
  background: transparent;
  color: #dc3545;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #fff5f5;
  }

  svg {
    font-size: 20px;
    min-width: 20px;
  }

  span {
    margin-left: 12px;
    font-weight: 500;
    opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
    transition: opacity 0.3s ease;
    white-space: nowrap;
  }
`;

export const ToggleButton = styled.button<SidebarProps>`
  position: absolute;
  right: -12px;
  top: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    background: #f5f5f5;
    transform: scale(1.1);
  }

  svg {
    font-size: 14px;
    color: ${Cores.textDark};
    transition: transform 0.3s ease;
    transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0)")};
  }
`;
