import { useLocation, useNavigate } from "react-router-dom";
import {
  FaClipboardList,
  FaSignOutAlt,
  FaUsers,
  FaChevronRight,
} from "react-icons/fa";
import {
  SidebarContainer,
  LogoContainer,
  MenuList,
  MenuItem,
  LogoutButton,
  ToggleButton,
} from "./styles";
import { signOut } from "firebase/auth";
import { auth } from "../../../config/firebase";
import logo from "../../../assets/image/Logo.webp";

interface SidebarProps {
  isOpen: boolean;
  toggleSidebar: () => void;
}

const Sidebar = ({ isOpen, toggleSidebar }: SidebarProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error("Erro ao fazer logout:", error);
    }
  };

  return (
    <SidebarContainer isOpen={isOpen}>
      <ToggleButton isOpen={isOpen} onClick={toggleSidebar}>
        <FaChevronRight />
      </ToggleButton>

      <LogoContainer isOpen={isOpen}>
        <img src={logo} alt="logo" />
        {isOpen && <h1>Engenhoso Cell</h1>}
      </LogoContainer>

      <MenuList>
        <MenuItem
          active={location.pathname === "/admin/clientes"}
          onClick={() => navigate("/admin/clientes")}
        >
          <FaUsers />
          {isOpen && <span>Clientes</span>}
        </MenuItem>
        <MenuItem
          active={location.pathname === "/admin/orcamentos"}
          onClick={() => navigate("/admin/orcamentos")}
        >
          <FaClipboardList />
          {isOpen && <span>Orçamentos</span>}
        </MenuItem>
      </MenuList>

      <LogoutButton isOpen={isOpen} onClick={handleLogout}>
        <FaSignOutAlt />
        {isOpen && <span>Sair</span>}
      </LogoutButton>
    </SidebarContainer>
  );
};

export default Sidebar;
