import styled from "styled-components";
import { Cores } from "../styles/GlobalStyles";

interface AdminContainerProps {
  isSidebarOpen?: boolean;
}

export const AdminContainer = styled.div<AdminContainerProps>`
  width: 100%;
  min-height: 100vh;
  background-color: ${Cores.primary};
`;

export const MainContent = styled.main<AdminContainerProps>`
  flex-grow: 1;
  transition: margin-left 0.3s ease;

  @media (max-width: 768px) {
    margin-left: 60px;
  }
`;
