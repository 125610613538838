// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAIUzhS2HPNy28MRHfveO8qSi0_Qv-InQM",
  authDomain: "engenhoso-cell.firebaseapp.com",
  projectId: "engenhoso-cell",
  storageBucket: "engenhoso-cell.firebasestorage.app",
  messagingSenderId: "313623262610",
  appId: "1:313623262610:web:064fe59659301b38f03d4b",
  measurementId: "G-5X9TRD0C6Y",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);
