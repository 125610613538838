import { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  query,
  orderBy,
  updateDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../../config/firebase";
import {
  Header,
  RequestsGrid,
  RequestCard,
  InfoGrid,
  InfoItem,
  LoadingMessage,
  Button,
  PaymentForm,
  Input,
  ButtonGroup,
  ConfirmButton,
  CancelButton,
  TabsContainer,
  Tab,
  DashboardCss,
} from "./styles";

interface Orcamento {
  id: string;
  name: string;
  phone: string;
  email: string;
  brand: string;
  model: string;
  issue: string;
  description: string;
  timestamp: Date;
  status: string;
  valorPago?: number;
}

const Dashboard = () => {
  const [orcamentos, setOrcamentos] = useState<Orcamento[]>([]);
  const [loading, setLoading] = useState(true);
  const [editingId, setEditingId] = useState<string | null>(null);
  const [valorPago, setValorPago] = useState<string>("");
  const [expandedId, setExpandedId] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<
    "novos" | "pendentes" | "concluidos"
  >("novos");

  useEffect(() => {
    const fetchOrcamentos = async () => {
      try {
        const q = query(
          collection(db, "Orcamentos"),
          orderBy("timestamp", "desc")
        );

        const querySnapshot = await getDocs(q);
        const orcamentosData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp?.toDate(),
        })) as Orcamento[];

        setOrcamentos(orcamentosData);
      } catch (error) {
        console.error("Erro ao buscar orçamentos:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrcamentos();
  }, []);

  const handleConcluirOrcamento = async (id: string) => {
    if (!valorPago || isNaN(Number(valorPago))) {
      alert("Por favor, insira um valor válido");
      return;
    }

    try {
      await updateDoc(doc(db, "Orcamentos", id), {
        status: "Concluído",
        valorPago: Number(valorPago),
      });

      setOrcamentos((prevOrcamentos) =>
        prevOrcamentos.map((orcamento) =>
          orcamento.id === id
            ? {
                ...orcamento,
                status: "Concluído",
                valorPago: Number(valorPago),
              }
            : orcamento
        )
      );

      setEditingId(null);
      setValorPago("");
      alert("Orçamento atualizado com sucesso!");
    } catch (error) {
      console.error("Erro ao atualizar orçamento:", error);
      alert("Erro ao atualizar orçamento. Tente novamente.");
    }
  };

  const filteredOrcamentos = orcamentos.filter((orcamento) => {
    switch (activeTab) {
      case "novos":
        return orcamento.status === "Pendente";
      case "pendentes":
        return orcamento.status === "Pendente";
      case "concluidos":
        return orcamento.status === "Concluído";
      default:
        return true;
    }
  });

  if (loading) {
    return <LoadingMessage>Carregando...</LoadingMessage>;
  }

  return (
    <DashboardCss>
      <Header>
        <h1>Painel de Orçamentos</h1>
      </Header>

      <TabsContainer>
        <Tab
          active={activeTab === "novos"}
          onClick={() => setActiveTab("novos")}
        >
          Novos
        </Tab>
        <Tab
          active={activeTab === "pendentes"}
          onClick={() => setActiveTab("pendentes")}
        >
          Pendentes
        </Tab>
        <Tab
          active={activeTab === "concluidos"}
          onClick={() => setActiveTab("concluidos")}
        >
          Concluídos
        </Tab>
      </TabsContainer>

      <RequestsGrid>
        {filteredOrcamentos.map((orcamento) => (
          <RequestCard
            key={orcamento.id}
            onClick={() =>
              setExpandedId(expandedId === orcamento.id ? null : orcamento.id)
            }
          >
            <InfoGrid>
              <InfoItem>
                <h3>Cliente:</h3> {orcamento.name}
              </InfoItem>
              <InfoItem>
                <strong>Data da Solicitação:</strong>
                {orcamento.timestamp?.toLocaleDateString("pt-BR", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </InfoItem>

              {expandedId === orcamento.id && (
                <>
                  <h3>Contato</h3>
                  <InfoItem>
                    <strong>Telefone:</strong> {orcamento.phone}
                  </InfoItem>
                  <InfoItem>
                    <strong>Email:</strong> {orcamento.email}
                  </InfoItem>
                  <hr />
                  <h4>Dados do Aparelho</h4>
                  <InfoItem>
                    <strong>Marca:</strong> {orcamento.brand}
                  </InfoItem>
                  <InfoItem>
                    <strong>Modelo:</strong> {orcamento.model}
                  </InfoItem>
                  <hr />
                  <h4>Serviço</h4>
                  <InfoItem>
                    <strong>Problema:</strong> {orcamento.issue}
                  </InfoItem>
                  <InfoItem className="description">
                    <strong>Descrição:</strong> {orcamento.description}
                  </InfoItem>
                  <hr />
                  <h4>Status do Serviço</h4>
                  <InfoItem>
                    <strong>Status:</strong> {orcamento.status}
                  </InfoItem>
                  {orcamento.valorPago && (
                    <InfoItem>
                      <strong>Valor Pago:</strong> R${" "}
                      {orcamento.valorPago.toFixed(2)}
                    </InfoItem>
                  )}

                  {editingId === orcamento.id ? (
                    <PaymentForm onClick={(e) => e.stopPropagation()}>
                      <Input
                        type="number"
                        placeholder="Digite o valor pago"
                        value={valorPago}
                        onChange={(e) => setValorPago(e.target.value)}
                      />
                      <ButtonGroup>
                        <ConfirmButton
                          onClick={() => handleConcluirOrcamento(orcamento.id)}
                        >
                          Confirmar
                        </ConfirmButton>
                        <CancelButton
                          onClick={() => {
                            setEditingId(null);
                            setValorPago("");
                          }}
                        >
                          Cancelar
                        </CancelButton>
                      </ButtonGroup>
                    </PaymentForm>
                  ) : (
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditingId(orcamento.id);
                      }}
                      disabled={orcamento.status === "Concluído"}
                    >
                      {orcamento.status === "Concluído"
                        ? "Concluído"
                        : "Concluir Orçamento"}
                    </Button>
                  )}
                </>
              )}
            </InfoGrid>
          </RequestCard>
        ))}
      </RequestsGrid>
    </DashboardCss>
  );
};

export default Dashboard;
