import styled from "styled-components";
import { Cores } from "../../styles/GlobalStyles";

export const ReviewsCss = styled.section`
  width: 100%;
  padding: 80px 0;
  background-color: ${Cores.secondary};
`;

export const Title = styled.div`
  text-align: center;
  margin-bottom: 60px;

  h2 {
    font-size: 2rem;
    color: ${Cores.textDark};
    margin-bottom: 16px;
  }

  p {
    font-size: 1rem;
    color: #666;
  }
`;

export const ReviewsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 24px;
  margin: 0 auto;
`;

export const ReviewCard = styled.div`
  background-color: ${Cores.secondary};
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  .header {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 12px;

    .profile-image {
      width: 40px;
      height: 40px;
      background-color: #ccc;
      border-radius: 50%;
      object-fit: cover;
    }

    .profile-info {
      flex: 1;

      h3 {
        font-size: 1.2rem;
        color: ${Cores.textDark};
        margin-bottom: 4px;
      }

      .rating {
        display: flex;
        gap: 4px;
        color: #ffd700;
      }
    }
  }

  .service {
    display: inline-block;
    background-color: #e8f4fd;
    color: #0066cc;
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 0.9rem;
    margin-bottom: 16px;
  }

  .comment {
    color: #666;
    line-height: 1.6;
    margin-bottom: 16px;
  }

  .date {
    display: block;
    color: #999;
    font-size: 0.9rem;
    text-align: right;
  }
`;
